.seo-panel {
	--show-more-width: 5.5rem;
	--show-more-height: 1.5rem;
	--bottom-padding: 1rem;
	--side-padding: 0.5rem;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	color: #4e4b4c;
	background-color: #e6e6e6;
	padding: 1rem var(--side-padding) var(--show-more-height);
	margin-top: 2rem !important;
}

.seo-panel:not(.is-expanded) {
	flex-direction: row;
	gap: 0.5rem;
}

.toggledContent {
	flex: 1;
	overflow: hidden;
}

.toggleButton {
	flex-shrink: 0;
	align-self: flex-end;
	margin-bottom: 0.325rem;
}

.toggleButton.is-expanded {
	margin-bottom: 0;
}

.title {
	max-height: 3.2rem;
	font: 500 1.375rem / 1.091 var(--font-family-main) !important;
	overflow: hidden;
	padding-bottom: 1rem;
}
.is-expanded .title {
	grid-column: unset;
}

.content {
	width: 100%;
	height: 2.5rem;
	font: 400 1rem / 1.25 var(--font-family-main) !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.is-expanded .content {
	width: 100%;
	height: 100%;
	padding-bottom: 0.5rem;
	white-space: pre-line;
}

.show-more {
	height: var(--show-more-height);
	position: absolute;
	bottom: var(--bottom-padding);
	right: var(--side-padding);
	color: #406dba;
	font: 400 1rem / 1.5 var(--font-family-main) !important;
	text-decoration: underline;
	cursor: pointer;
	text-align: right;
	outline: 0;
}
.show-more::before {
	content: attr(data-show-more);
}

.show-more:hover {
	color: var(--color-blue-600);
}

.show-more:focus-visible:not(:active):before,
.show-more:focus:not(:active):before {
	outline: 2px solid #1049a9;
	border-radius: 2px;
}

.show-more:active {
	color: var(--color-blue-800);
}

.toggle {
	display: none;
}

[data-round_corners='true'] .seo-panel {
	border-radius: 1rem;
}

[data-theme='dark'] .seo-panel {
	color: #fff;
	background-color: #231f20;
}

.seeMoreButton {
	background-color: transparent;
	color: var(--color-blue-400);
	text-decoration: underline;
}

.seeMoreButton:hover {
	cursor: pointer;
}

@media (min-width: 1025px) {
	.seo-panel {
		--side-padding: 1.5rem;
		--bottom-padding: 1.5rem;
		margin-top: 3rem !important;
		max-width: 65.5rem;
		padding: 1rem var(--side-padding) var(--bottom-padding);
		width: calc(100% - (2 * var(--grid-margins)));
	}

	.seo-panel:not(.is-expanded) {
		flex-direction: row;
	}

	.title {
		max-height: 5.5rem;
		font: 500 2rem / 1.5 var(--font-family-main) !important;
	}

	.content {
		height: 1.5rem;
		font: 400 1.125rem / 1.333 var(--font-family-main) !important;
		padding-bottom: 0;

	}
	.is-expanded .content {
		padding: 1rem 0;
	}
	.show-more {
		bottom: var(--bottom-padding);
		right: var(--side-padding);
	}
}
