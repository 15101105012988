.horizontalPillWrapper {
	display: flex;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	padding-left: 3.5rem;
	/* Use padding instead of margin */
	margin: 1rem 0;
	/* Adjust margins as needed */
}

/* Ensure the parent container allows the child to overflow */
.horizontalPill {
	display: flex;
	align-items: center;
	position: relative;
	flex-wrap: nowrap;
	/* Prevent wrapping */
}

/* When pillsWithSidebar is active */
.pillsWithSidebar {
	padding-left: 4.5rem;
}

/* The scrollable container */
.pillsContainer {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	overflow-x: auto;
	flex: 1 1 auto;
	scroll-behavior: smooth;
	min-width: 0; /* Prevent content overflow */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar */
.pillsContainer::-webkit-scrollbar {
	display: none;
}

.title {
	flex: 0 0 auto;
	/* Default behavior, can be omitted */
	display: block;
}

.arrowButton {
	color: var(--color-gray-500);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	cursor: pointer;
	box-shadow: 1px 1px 5px 2px var(--color-black-10);
	background-color: var(--color-gray-50);
}

.arrowButton:active {
	box-shadow:
		rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.buttonPrev {
	position: absolute;
	z-index: 2;
	left: -0.7rem;
}

.buttonNext {
	position: absolute;
	z-index: 2;
	right: 1rem;
}

/* Desktop view */
body[data-device-type='desktop'] .horizontalPill {
	flex: 1 1 auto;
	min-width: 0;
	padding-left: 0.6rem;
}

body[data-device-type='desktop'] .horizontalPill div:last-of-type {
	padding-right: 0.5rem;
}

body[data-device-type='desktop'] .pillsFromPages {
	margin-left: 0 !important;
	padding-right: calc(var(--grid-margins) + 1.2rem);
}

body[data-device-type='desktop'] .pillsFromPages .buttonNext {
	right: -1.2rem;
}

body[data-device-type='desktop'] .pillsFromPages div:last-of-type {
	padding-right: 0;
}

/* Mobile/Tablet view */
body[data-device-type='mobile'] .horizontalPillWrapper,
body[data-device-type='tablet'] .horizontalPillWrapper {
	flex-wrap: nowrap;
	overflow-x: scroll;
	padding-bottom: 1rem;
	flex: 0 0 auto;
	margin: 1rem 0 !important;
	padding: 0;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
	-webkit-overflow-scrolling: touch;
}

body[data-device-type='mobile'] .pillsFromPages,
body[data-device-type='tablet'] .pillsFromPages {
	margin: 0 !important;
}

body[data-device-type='mobile'] .horizontalPill,
body[data-device-type='tablet'] .horizontalPill {
	padding: 0 1rem;
}

body[data-device-type='mobile'] .horizontalPillWrapper::-webkit-scrollbar,
body[data-device-type='tablet'] .horizontalPillWrapper::-webkit-scrollbar {
	display: none;
}

body[data-device-type='mobile'] .title,
body[data-device-type='tablet'] .title {
	display: none;
}
