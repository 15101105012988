.header {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	margin-top: 1.5rem;
}

/* Adding !important to font to beat global styling */
.headline {
	margin-top: 0;
	font: 400 1.5rem / 1.166 var(--font-family-main) !important;
	margin-bottom: 0.5rem;
	word-break: break-word;
}

.headline.not-found {
	font-weight: 600 !important;
}

.headline .query {
	font: 600 1.5rem / 1.166 var(--font-family-main) !important;
}

[data-theme='dark'] .header {
	color: white;
}

/* Desktop view */
body[data-device-type='desktop'] .header {
	grid-template-columns: 1fr auto;
	align-items: center;
	row-gap: 1.5rem;
	padding-right: var(--grid-margins);
}

body[data-device-type='desktop'] .header > details,
body[data-device-type='desktop'] .header:not(:has(fieldset)) > details {
	grid-row: unset;
}

body[data-device-type='desktop'] .headline {
	font: 400 1.5rem / 1.667 var(--font-family-main) !important;
	margin-bottom: 0;
}

body[data-device-type='desktop'] .headline .query {
	font: 600 1.5rem / 1.667 var(--font-family-main) !important;
}

/* Mobile and Tablet styles */
body[data-device-type='mobile'] .header > h1,
body[data-device-type='tablet'] .header > h1,
body[data-device-type='mobile'] .header > h2,
body[data-device-type='tablet'] .header > h2 {
	margin: 0 0 0 var(--grid-margins);
}

body[data-device-type='mobile'] .header > details,
body[data-device-type='tablet'] .header > details {
	margin: 0 0 0 var(--grid-margins);
	width: calc(100% - 2 * var(--grid-margins));
}

body[data-device-type='mobile'] [class*='tag-page'] .header,
body[data-device-type='tablet'] [class*='tag-page'] .header {
	margin-bottom: 0.5rem;
}

body[data-device-type='mobile'] [class='tag-page'] .header,
body[data-device-type='mobile'] [class='category-page'] .header,
body[data-device-type='mobile'] [class='search-page'] .header,
body[data-device-type='tablet'] [class='tag-page'] .header,
body[data-device-type='tablet'] [class='category-page'] .header,
body[data-device-type='tablet'] [class='search-page'] .header {
	gap: 1rem;
}

body[data-device-type='mobile'] [class='category-page'] .header > h2,
body[data-device-type='mobile'] [class='search-page'] .header > h1,
body[data-device-type='mobile'] [class='tag-page'] .header > h1,
body[data-device-type='tablet'] [class='category-page'] .header > h2,
body[data-device-type='tablet'] [class='search-page'] .header > h1,
body[data-device-type='tablet'] [class='tag-page'] .header > h1 {
	grid-row: 1;
}

body[data-device-type='mobile'] [class='category-page'] .header > div,
body[data-device-type='mobile'] [class='search-page'] .header > div,
body[data-device-type='tablet'] [class='category-page'] .header > div,
body[data-device-type='tablet'] [class='search-page'] .header > div {
	grid-row: 2;
}

body[data-device-type='mobile'] [class='category-page'] .header > details,
body[data-device-type='mobile'] [class='search-page'] .header > details,
body[data-device-type='tablet'] [class='category-page'] .header > details,
body[data-device-type='tablet'] [class='search-page'] .header > details {
	grid-row: 3;
}

body[data-device-type='mobile'] [class='tag-page'] .header > details,
body[data-device-type='tablet'] [class='tag-page'] .header > details {
	grid-row: 2;
}

body[data-device-type='mobile'] .headline,
body[data-device-type='tablet'] .headline {
	margin-bottom: 0.5rem !important;
}
