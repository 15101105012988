/* The reason to have this specification is because global 
styles is overriding some of the styles of the badge component. */
.badge.primary {
	display: flex;
	height: 1.25rem;
	padding: 0 0.375rem;
	justify-content: center;
	align-items: center;
	border-radius: 0rem 0.5rem;
	text-align: center;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1rem;
	vertical-align: middle;
	text-transform: uppercase;
	position: absolute;
	z-index: 90;
	top: -0.25rem;
	left: -0.25rem;
}
