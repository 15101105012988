.toggle {
	position: relative;
	list-style: none;
	cursor: pointer;
	width: 100%;
	height: 2.5rem;
	box-shadow: inset 0 0 0 2px #d1d0d0;
	font: 400 1rem / 1.5 var(--font-family-main);
	outline: none;
	z-index: 20005;
}
.toggle:before {
	display: var(--display-backdrop);
	content: '';
	position: absolute;
	z-index: 2;
	top: 3.5rem;
	width: 100%;
	height: 13.5rem;
	padding: 0.5rem 0;
}

.selected {
	width: 100%;
	height: 100%;
	padding: 0 1rem;
	user-select: none;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	outline: none;
	color: #4e4b4c;
	white-space: pre;
}

.selected > span {
	text-overflow: ellipsis;
	overflow: hidden;
}

.selected:is(:focus, :focus-visible):before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: inset 0 0 0 2px #1049a9;
}

.selected::marker,
.selected::-webkit-details-marker {
	display: none;
}
.toggle[open] > .selected svg {
	transform: rotate(180deg);
}

.content {
	position: absolute;
	top: 3.5rem;
	z-index: 30000; /* Needs to be higher than the badges and std card hover element on uikit */
	width: 100%;
	max-height: 13.5rem;
	overflow-y: auto;
	box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
	background: white;
	padding: 0.5rem 0;
}

.list {
	display: flex;
	flex-direction: column;
}
.item {
	padding: 0 1rem;
	height: 2.5rem;
	line-height: 1.5;
	text-align: left;
	background: transparent;
	cursor: pointer;
	color: #4e4b4c;
	outline: none;
	user-select: none;
}
.item:hover {
	background: #e6e6e6;
}
.item:focus-visible {
	box-shadow: inset 0 0 0 2px #1049a9;
}

[data-round_corners='true'] :is(.toggle, .selected, .content) {
	border-radius: 0.5rem;
}

[data-theme='dark'] .toggle {
	background: #656263;
	box-shadow: inset 0 0 0 2px #a5a4a4;
}
[data-theme='dark'] .selected {
	color: white;
}
[data-theme='dark'] .content {
	background: #656263;
}
[data-theme='dark'] .item {
	color: white;
}
[data-theme='dark'] .item:hover {
	background: #a5a4a4;
}

@media (min-width: 1025px) {
	.toggle {
		width: 15rem;
	}
	.content,
	.toggle[open]:before {
		width: 15rem;
	}
}
