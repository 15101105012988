.results {
	--card-width: 11rem;
	--card-height: 6rem;
	--gap: 0.5rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, var(--card-width));
	grid-template-rows: repeat(auto-fill, var(--card-height));
	justify-content: center;
	gap: var(--gap);
	color: black;
	overflow: hidden;
	padding: 0.25rem 0 0 0.25rem; /* Both on cat and tag pages grid, if we have badges on top we also need some top padding (not only left - badge position) */
}

.adsDivider {
	position: relative;
	grid-column: 1 / -1; /* Span the entire row */
	display: flex;
	justify-content: center;
}

.adsDivider.desktop {
	height: 90px; /* ads 728x90 */
}
.adsDivider.mobile {
	height: 250px; /* ads 300x250 */
	margin: 0.5rem 0;
}

@media screen and (max-width: 392px) {
	.results {
		--card-width: 48%;
		--card-height: 5rem;
	}
}

.titleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.title {
	font: 500 1.125rem / 1.111 var(--font-family-main) !important;
}

.seeAll {
	font: 600 1.125rem / 1.333 var(--font-family-main) !important;
	text-decoration: underline;
	color: black;
}

@media (min-width: 1025px) {
	.results {
		--card-width: 17rem;
		--card-height: 9.5rem;
		--gap: 1rem;
		justify-content: left;
	}

	.title {
		font: 500 2rem / 1.5 var(--font-family-main) !important;
	}

	.viewMore {
		width: 17rem;
		margin-top: 5rem;
	}
}
