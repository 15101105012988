.tags {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.title {
	color: #000;
	font: 600 1.5rem / 1.166 var(--font-family-main) !important;
}

.list {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.tag {
	--normal-background: transparent;
	--normal-color: #656263;
	--normal-border: #e6e6e6;
	--hover-background: transparent;
	--hover-color: #656263;
	--hover-border: #656263;
	--active-background: transparent;
	--active-color: #656263;
	--active-border: #bbbaba;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 11rem;
	height: 2.5rem;
	padding-left: 2.5rem;
	border: 2px solid var(--normal-border);
	background: no-repeat left / 2.5rem var(--image, linear-gradient(gray, gray));
	background-color: var(--normal-background);
	color: var(--normal-color);
	outline: none;
}

.tag:hover {
	border-color: var(--hover-border);
	background-color: var(--hover-background);
	color: var(--hover-color);
}

.tag:active {
	border-color: var(--active-border);
	background-color: var(--active-background);
	color: var(--active-color);
}
.tag:focus-visible:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 0 2px #1049a9;
}

.name {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	overflow: hidden;
	word-break: break-all;
	font: 500 0.875rem / 1rem var(--font-family-main) !important;
	padding: 0 0.5rem;
}

[data-theme='dark'] .title {
	color: white;
}

[data-theme='dark'] .tag {
	--normal-color: white;
	--normal-border: #656263;
	--normal-background: transparent;
}

[data-theme='dark'] .tag:hover {
	--hover-color: white;
	--hover-border: white;
	--hover-background: transparent;
}

[data-theme='dark'] .tag:active {
	--active-color: white;
	--active-border: #4e4b4c;
	--active-background: transparent;
}

[data-round_corners='true'] .tag {
	border-radius: 0.5rem;
}

@media (min-width: 1025px) {
	.title {
		font: 600 1.5rem / 1.666 var(--font-family-main) !important;
	}

	.tag {
		width: 13.625rem;
		height: 3.5rem;
		padding-left: 3.5rem;
		background: no-repeat left / 3.5rem var(--image, linear-gradient(gray, gray));
		background-color: var(--normal-background);
	}

	.name {
		font: 500 1rem / 1.5 var(--font-family-main) !important;
	}
}
