.link {
	position: relative;
	width: 11rem;
	height: 6rem;
	overflow: visible;
	border-radius: 0;
}

@media screen and (max-width: 392px) {
	.link {
		width: 100%;
		height: 5rem;
	}
}

/* gray border */
.link:hover:has(.hover-block:not(.full)):before,
.link:focus:has(.hover-block:not(.full)):before{
	content: '';
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: inset 0px 0px 0px 4px #bbbaba;
	border-radius: inherit;
}

/* Hover block */
.hover-block {
	--hover-block-color: white;
	--hover-block-background: rgba(0, 0, 0, 0.7);
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	height: 6.5rem;
	position: absolute;
	top: 9.5rem;
	left: 0;
	padding: 0.5rem;
	color: var(--hover-block-color);
	background: var(--hover-block-background);
	backdrop-filter: blur(2.5px);
	transition:
		top 0.2s ease,
		visibility 0.2s ease;
	visibility: hidden;
}

[data-round_corners='true'] .hover-block.full {
	border-radius: 0.5rem;
}

[data-round_corners='true'] .hover-block.partial {
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}

.hover-block.full {
	height: 100%;
}
.link:hover .hover-block.full,
.link:focus .hover-block.full{
	top: 0;
	visibility: visible;
}
.hover-block.partial {
	display: none;
}
.link:hover .hover-block.partial,
.link:focus .hover-block.partial{
	top: 3rem;
	visibility: visible;
}

.link:focus {
	outline: 2px solid var(--color-blue-500) !important;
}
.link:focus > .hover-block {
	transition: none;
}
.link:focus .hover-block.full {
	top: 0;
}
.link:focus .hover-block.partial {
	top: 3rem;
}

.title {
	font-weight: 600 !important;
	font-size: 0.875rem !important;
	line-height: 1.142 !important;
	overflow: hidden;
	line-break: loose;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.description {
	font-weight: 400 !important;
	font-size: 0.75rem !important;
	line-height: 1.333 !important;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
}
@media screen and (max-width: 392px) {
	.description {
		-webkit-line-clamp: 2;
		line-clamp: 2;
	}
}

.hover-block > button {
	display: none;
	width: -webkit-fit-content;
	width: fit-content;
	height: 2.5rem;
	padding: 0 1.5rem;
	margin: 0.375rem auto 0 0;
}

.play-icon {
	margin-right: 0.5rem;
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

[data-round_corners='true'] .background {
	border-radius: 0.5rem;
}

.cardWrapper {
	overflow: hidden;
	height: 100%;
	position: relative;
}

@media (min-width: 1025px) {
	.link {
		position: relative;
		width: 17rem;
		height: 9.5rem;
	}

	.link:hover:has(.hover-block:is(.full, .partial)):before {
		box-shadow: none;
	}

	.hover-block {
		padding: 1rem;
	}
	.hover-block.partial {
		display: flex;
		padding: 0.5rem 1rem 1rem;
		visibility: hidden;
	}

	.title {
		font-size: 1rem !important;
		line-height: 1.5 !important;
	}

	/* Description */
	.description {
		font-size: 0.875rem !important;
		line-height: 1.142 !important;
	}
	.hover-block.full .description {
		-webkit-line-clamp: 2;
		line-clamp: 2;
	}

	.hover-block > button {
		display: flex;
	}
}

.__catPageBtn {
	max-height: 2.5rem;
}

.link a:has(.__catPageBtn) {
	margin-top: auto;
}
